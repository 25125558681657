<template v-if="isLoggedIn">
    <div class="container page">
        <template v-if="pgMode=='view'">
            <div class="mt-5 mb-5 pb-5">
                <div><ad-rotator rotatorId="adreward1" rotatorType="adreward" :controls="false" :indicators="false"></ad-rotator></div>
                <div><content-db contentKey="topMsgRewards" :isCache="true"></content-db></div>
                <div class="row mt-3">
                    <div class="col-sm-3 hidden-print">
                        <button type="button" class="btn btn-primary w-100 mb-1" data-toggle="tooltip" data-placement="right" @click="$router.push({ path: 'basket' })"><b-icon icon="cart" aria-hidden="true"></b-icon> {{ `Basket / Checkout (${totalBasketQty} item${totalBasketQty == 1 ? '' : 's'})` }}</button>

                        <b-card no-body>
                            <div v-for="cat in cats" :key="cat.name">
                                <b-card-header header-tag="header" class="" role="tab">
                                    <button v-if="cat.id.length===0" class="btn btn-link catL1" type="button" v-b-toggle="cat.key">{{ cat.name }}</button>
                                    <button v-else class="btn btn-link catL1" type="button" v-b-toggle="cat.key" @click="$router.push({ path: cat.key + '' })">{{ cat.name }}</button>
                                </b-card-header>
                                <b-collapse :id="cat.key" accordion="my-accordion" role="tabpanel" v-model="cat.visible">
                                    <b-card-body v-if="cat.id.length===0">
                                        <div v-for="subCat in cat.subCats" :key="subCat.key">
                                            <button class="ml-2 btn btn-link catL2" type="button" v-on:click="$router.push({ path: subCat.key + '' })">
                                                {{ subCat.name }}
                                            </button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </div>
                        </b-card>

                    </div>

                    <div class="col-sm-9">
                        <h2>{{ catName }}</h2>
                        <hr />
                        <div v-for="prd in products" :key="prd._rowNum">
                            <div class="row">
                                <div class="col-sm-4 text-center">
                                    <div><img :src="'https://cdn.rewardedco.com/product/' + prd.imageFile" border="0" alt="" width="100" height="100" class="rounded" /></div>
                                    <h1>{{ prd.priceFormatted }}</h1>
                                    <h5>{{ prd.priceDescription }}</h5>
                                </div>
                                <div class="col-sm-8">
                                    <h4>{{ prd.description }}</h4>
                                    <h5 v-if="prd.brand !== '' || prd.model !== ''">{{ prd.brand + (prd.brand !== '' && prd.model !== '' ? ' - ' : '') + prd.model }}</h5>
                                    <div class="shopfab" v-html="htmlrep(prd.fAB)"></div>
                                    <!-- if (p.itemdisplaytype == 0) {-->
                                    <div v-if="prd.noBasket === 'N'" class="shopCart">Call <strong>clientPhone</strong></div>
                                    <div v-else class="shopCart"><button type="button" class="btn btn-primary" @click="addToBasket(prd.productId)"><b-icon icon="cart-plus" aria-hidden="true"></b-icon> add to basket</button></div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>

                </div>

            </div>
        </template>


        <template v-else-if="pgMode=='basket'">
            <div class="row">
                <div class="col"><h1>Your Basket</h1></div>
                <div class="col text-right"><button type="button" class="btn btn-secondary mr-3" @click="$router.push({ path: '/rewards/' + lastNavCat });">Back</button></div>
            </div>

            <template v-if="totalBasketQty===0">
                <div class="alert alert-warning">There are no items in your basket</div>
            </template>

            <template v-else>
                <div class="card mb-4">
                    <div class="card-body">
                        <div>
                            <h5>{{ `${totalBasketQty} item${totalBasketQty == 1 ? '' : 's'} in your basket` }}</h5>
                            <h5 class="my-3">{{ `Total: ${formatMoneyInclCents(totalBasketPrice)}` }}</h5>
                        </div>
                        <b-form @submit.prevent="checkout" novalidate>
                            <b-form-group v-if="false" id="promoCodeGrp" label-for="promoCodeTxt" label-cols-lg="2" label-cols-sm="4" label="Promotional Code:" :invalid-feedback="fBasket.errors.promoCode" description="If you have a valid promotional code, enter it here prior to checkout.">
                                <b-form-input type="text" id="promoCodeTxt" v-model="fBasket.form.promoCode" :state="fBasket.state.promoCode" maxlength="20" style="max-width:15rem" />
                            </b-form-group>
                            <div class="text-right"> <button type="submit" class="btn btn-primary">Check Out</button> </div>
                        </b-form>
                    </div>
                </div>

                <h3>Your Basket Items:</h3>
                <hr />
                <div v-for="prd in basket" :key="prd._rowNum">
                    <div class="row">

                        <div class="col-sm-4 text-center">
                            <div><img :src="'https://cdn.rewardedco.com/product/' + prd.imageFile" border="0" alt="" width="100" height="100" class="rounded" /></div>
                            <h2>{{ formatMoney(prd.displayPrice) }}</h2>
                            <h5>{{ prd.priceDescription }}</h5>

                            <div v-if="prd.BasketItemStatus == 'DELETED'" class="alert alert-warning">This product is no longer available and has been removed from your basket</div>
                            <div v-else-if="prd.BasketItemStatus == 'CHANGED'" class="alert alert-warning">The price for this product has changed from {{ formatMoney(prd.basketPrice) }} to {{ formatMoney(prd.displayPrice) }}</div>
                            <div v-else-if="prd.BasketItemStatus == 'DECREASED'" class="alert alert-warning">The price for this product has been reduced from {{ formatMoney(prd.basketPrice) }} to {{ formatMoney(prd.displayPrice) }}</div>

                            <div><b>{{`${prd.qty} item${prd.qty == 1 ? '' : 's'}`}}</b></div>
                            <h5>Total: {{ formatMoney(prd.itemTotal) }}</h5>

                            <div class="btn-group mb-2">
                                <button type="button" class="btn btn-outline-primary px-3" v-b-tooltip.hover title="Increase Quantity" @click="changeBasketQty('add', prd.shopBasketId)"><b-icon icon="cart-plus" aria-hidden="true"></b-icon></button>
                                <button type="button" class="btn btn-outline-primary px-3" v-b-tooltip.hover :disabled="prd.qty<=1" :title="prd.qty <= 1 ? '' : 'Decrease Quantity'" @click="changeBasketQty('decrease', prd.shopBasketId)"><b-icon icon="cart-dash" aria-hidden="true"></b-icon></button>
                                <button type="button" class="btn btn-outline-primary px-3" v-b-tooltip.hover title="Remove this Item" @click="changeBasketQty('delete', prd.shopBasketId)">Remove</button>
                            </div>

                        </div>

                        <div class="col-sm-8">
                            <h4>{{ prd.description }}</h4>
                            <h5 v-if="prd.brand !== '' || prd.model !== ''">{{ prd.brand + (prd.brand !== '' && prd.model !== '' ? ' - ' : '') + prd.model }}</h5>
                            <div class="shopfab" v-html="htmlrep(prd.fAB)"></div>
                        </div>

                    </div>
                    <hr />
                </div>
            </template>
        </template>

        <template v-else-if="pgMode=='checkout'">
            <div class="row">
                <div class="col"><h1>Place Order</h1></div>
                <div class="col text-right"><button type="button" class="btn btn-secondary mr-3" @click="pgMode='basket'">Back</button></div>
            </div>

            <template v-if="totalBasketQty===0">
                <div class="alert alert-warning">There are no items in your basket</div>
            </template>

            <template v-else>

                <div class="card card-default mb-3">
                    <div class="card-header">Order Details</div>
                    <div class="card-body">
                        <div v-for="prd in basket" :key="prd._rowNum">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div v-if="prd.brand !== '' || prd.model !== ''">{{ prd.brand + (prd.brand !== '' && prd.model !== '' ? ' - ' : '') + prd.model }}</div>
                                    <div>{{ prd.description }}</div>

                                    <div v-if="prd.BasketItemStatus == 'DELETED'" class="alert alert-warning">This product is no longer available and has been removed from your basket</div>
                                    <div v-else-if="prd.BasketItemStatus == 'CHANGED'" class="alert alert-warning">The price for this product has changed from {{ formatMoney(prd.basketPrice) }} to {{ formatMoney(prd.displayPrice) }}</div>
                                    <div v-else-if="prd.BasketItemStatus == 'DECREASED'" class="alert alert-warning">The price for this product has been reduced from {{ formatMoney(prd.basketPrice) }} to {{ formatMoney(prd.displayPrice) }}</div>
                                </div>
                                <div class="col-sm-2"></div>
                                <div class="col-sm-2 text-left">{{ `${prd.priceDescription} ${prd.qty} @ ${formatMoney(prd.displayPrice)}`}}</div>
                                <div class="col-sm-2 text-right">{{ formatMoneyInclCents(prd.itemTotal) }}</div>
                            </div>
                            <hr />
                        </div>

                        <div class="text-right font-weight-bold">{{ `${totalBasketQty} item${totalBasketQty == 1 ? '' : 's'}: ${formatMoneyInclCents(totalBasketPrice)}` }}</div>'
                        <div v-if="isVoucherOk" class="text-right font-weight-bold">{{ `${voucherAmtDesc} Promotional Code (${fBasket.form.promoCode.toUpperCase()}): ${formatMoneyInclCents(shopVoucherDiscountTotal)}` }}</div>
                        <div class="text-right font-weight-bold">GRAND TOTAL: {{ formatMoneyInclCents(grandTotal) }}</div>
                    </div>
                </div>

                <b-form @submit.prevent="placeOrder" novalidate v-if="inf.deliveryType == 'C' || inf.deliveryType == 'Z'">
                    <div class="card card-default">
                        <template v-if="inf.deliveryType == 'C'">
                            <div class="card-header">Voucher codes will be sent using SMS to this mobile number:</div>
                            <div class="card-body">
                                <b-form-group id="cellNumGrp" label-for="cellNumTxt" label-cols-lg="2" label-cols-md="4" label="Mobile Number:" :invalid-feedback="fBasket.errors.cellNum" >
                                    <b-form-input type="text" id="cellNumTxt" v-model="fPlaceOrder.form.cellNum" :state="fBasket.state.cellNum" maxlength="20" style="max-width:15rem" />
                                </b-form-group>
                            </div>
                        </template>
                        <template v-else-if="inf.deliveryType == 'Z'">
                            <div class="card-header">Your Contact Details:</div>
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col-md-3 fldName">Mobile Number:</div>
                                    <div class="col-md-9 fldValue">
                                        <b>{{ member.cellNum }}</b><br />
                                        <small class="text-muted">Where indicated, voucher codes will be sent using SMS to this mobile number.</small>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-sm-8" style="line-height:3em; vertical-align:bottom"><sup>*</sup> By pressing on the Place Order button, you agree to our <a class="hvrlinkbutton" href="#" @click.prevent="$bvModal.show('modalTerms')">terms and conditions</a>.</div>
                                <div class="col-sm-4 text-right"><button type="submit" class="btn btn-primary btn-lg">Place Order <sup>*</sup></button></div>
                            </div>
                        </div>
                    </div>
                </b-form>






            </template>
        </template>




        <b-modal id="modalSomething" scrollable ok-only size="lg" title="About">
            <i>Modal</i>
        </b-modal>
    </div>

</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';
	import AdRotator from '@/components/AdRotator';

    export default {
        name: 'Rewards',
		components: { ContentDb, AdRotator },

        data() {
            return {
                pgMode: 'loading',
                cats: [],
                inf: { alloc: "", isLoggedIn: false, isPaygate: false, allocDesc: "", isTruncateCents: true, addToBasketType: "A", deliveryType: "C", productCurrency: "", currencySymbol: "", currencyDecimalPlaces: 0, isCurrencySymbolSuffix: true, pmtMethodTypes: "P", currencyRateMoney: 1.0000, pointsAvailable: 0 },
                catName: '', //selected category
                lastNavCat: '',
                products: [],
                basket: [],
                totalBasketQty: 0,
                totalBasketPrice: 0,
                fProfile: {
                    form: { interest1: false, firstname: '', email: '', cellNum: '', dOBdate: null, interestOtherDesc: '', otherInfo: '' }
                },
                fBasket: {
                    form: { promoCode: '' }
                },
                fPlaceOrder: {
                    form: { cellNum: '' }
                },
                isVoucherOk: false,
                grandTotal: 0,
                vfyTotal: 0,
                voucherAmtDesc: '',
                shopVoucherDiscountTotal: 0,

            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },
            isLoggedIn() {
                return this.$store.state.isLoggedIn && this.member.memberDivisionCode != 'sra_2104' && this.member.memberDivisionCode !== 'stm_5192' //Disable SanPay division from access
            },
        },

        created() {
			console.log('*created');
            this.formSetState(this.fBasket, true, true) //formObj, resetState, resetForm
            //this.doLoadCategories();
        },
        mounted() {
			console.log('*mounted');
        },
        //beforeRouteUpdate(to, from, next) {
        //    console.log('ContentPage.vue beforeRouteUpdate ' + this.theKey);
        //    //this.theKey = to.params.contentKey;
        //    this.doNavigation(to.params.urlParam)
        //    next();
        //},
		beforeRouteEnter(to, from, next) {
            next(vm => { // access to component instance via `vm` - i.e. use 'vm' instead of 'this'
                console.log('*beforeRouteEnter - ' + to.params.urlParam);
                //this.urlParam = to.params.urlParam;
                vm.doLoadCategories();

				//vm.doNavigation(to.params.urlParam);
            });
		},
		// when route changes and this component is already rendered, the logic will be slightly different.
		beforeRouteUpdate(to, from, next) {
            console.log('*beforeRouteUpdate(to) - ' + to.params.urlParam);
			this.doNavigation(to.params.urlParam);
			next()
		},
        methods: {
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            doLoadCategories() {
                axios.post('/api/shop/categorylist', {})
                    .then(({ data }) => {
                        const catsData = new sjData(data.cats).data;
                        this.totalBasketQty = data.totalBasketQty;
                        this.totalBasketPrice = data.totalBasketPrice;

                        let cats = [];
                        let subCats = [];
                        let isVisible = false;
                        let lastCat1 = null;
                        let firstProdCat3Id = null;
                        let firstKey = '';
                        for (const i of catsData) {

                            if (lastCat1 === null || i.cat1 != lastCat1.cat1) {
                                if (lastCat1 !== null) {
                                    const theKey = lastCat1.cat1.sjReplaceAllButCharWithDash().toLowerCase();
                                    if (firstProdCat3Id === null && subCats.length === 0) { //set the first item
                                        firstProdCat3Id = lastCat1.prodCat3Id;
                                        firstKey = theKey;
                                        isVisible = false;
                                    }
                                    cats.push({
                                        name: lastCat1.cat1,
                                        subCats: subCats,
                                        id: subCats.length !== 0 ? '' : lastCat1.prodCat3Id,
                                        key: theKey,
                                        visible: isVisible
                                    });
                                    isVisible = false;
                                }
                                subCats = [];
                                lastCat1 = i;
                            }
                            if (i.cat2.length !== 0) {
								const theKey = (i.cat1 + '_' + i.cat2 + '_' + i.cat3).sjReplaceAllButCharWithDash().toLowerCase();
                                if (firstProdCat3Id === null) { //set the first item
                                    firstProdCat3Id = i.prodCat3Id;
                                    firstKey = theKey;
                                    isVisible = true;
                                }
                                subCats.push({
                                    name: i.cat2 + (i.cat3.length === 0 ? '' : ('/' + i.cat3)),
                                    subCats: [],
                                    id: i.prodCat3Id,
                                    key: theKey,
                                });

                            }
                        }
                        if (lastCat1 !== null) {
                            const theKey = lastCat1.cat1.sjReplaceAllButCharWithDash().toLowerCase();
                            if (firstProdCat3Id === null && subCats.length === 0) { //set the first item
                                firstProdCat3Id = lastCat1.prodCat3Id;
								firstKey = theKey;
                                isVisible = false;
                            }

                            cats.push({
                                name: lastCat1.cat1,
                                subCats: subCats,
                                id: subCats.length !== 0 ? '' : lastCat1.prodCat3Id,
                                key: theKey,
                                visible: isVisible
                            });

                        }
                        this.cats = cats;
                        this.inf = data.inf;
                        this.lastNavCat = firstKey;
						console.log('doLoadCategories - set lastNavCat ' + firstKey);
						this.doNavigation(this.$route.params.urlParam);
                    })
                    .catch((error) => {
                        if (error) {
                            console.log(error);
                            this.msgBox('Error retrieving category data');
                        }
                    })
            },
            doNavigation(param) {
				
                if (typeof param === 'undefined') param = '';
                if (param == '' && this.lastNavCat == '') {
					console.log('doNavigation: param & lastNavCat are blank');
                    return
				} 
                if (param === 'basket') {
                    console.log('doNavigationBasket: ' + param);
					if (this.cats.length == 0) {
						console.log('doNavigation: cats not populated');
						return
                    }
                    this.getBasket();
                } else {
                    //try match cat & subcat
                    let prodCat3Id = 0;
					if (this.cats.length == 0) {
						console.log('doNavigation: cats not populated');
						return
                    }

                    for (let cat of this.cats) {
                        if (cat.subCats.length === 0) {
                            if (param === cat.key) {
                                prodCat3Id = cat.id;
                                break;
                            }
                        } else {
                            for (let subCat of cat.subCats) {
                                if (param === subCat.key) {
                                    prodCat3Id = subCat.id;
                                    cat.visible = true;
                                    //setTimeout(function () { cat.visible = true; }, 500);
                                    break;
                                }
                            }
                        }
                    }
                    if (prodCat3Id !== 0) {
                        console.log('doNavigationProd: ' + param);
                        this.lastProdCat3Id = prodCat3Id;
                        this.doLoadProducts(prodCat3Id);
                        this.pgMode = "view";
                    } else if (param !== '' && !isNaN(param)) {
                        console.log('doNavigationProd: ' + param);
                        const prodCat3Id = parseInt(param);
                        this.lastProdCat3Id = prodCat3Id;
                        this.doLoadProducts(prodCat3Id);
                        this.pgMode = "view";
                    } else {
                        if (this.lastNavCat != param) {
                            console.log('doNavigationOther: ' + param + ' nav to: ' + this.lastNavCat);
                            this.$router.push({ path: '/rewards/' + this.lastNavCat });
                            //this.doLoadProducts(this.lastProdCat3Id);
                            this.pgMode = "view";
                        } else {
							console.log('doNavigationOther: NONE! (' + param + ' nav to: ' + this.lastNavCat + ")");
						}
                    }
                }
            },
            doLoadProducts(id) {
                axios.post('/api/shop/productlist', { prodCat3Id: id, alloc: this.inf.alloc })
                    .then(({ data }) => {
                        //debugger;
                        //console.log(data);
                        this.catName = data.catName;
                        let products = new sjData(data.products).data;

                        for (let prd of products) {
                            var dp = prd.displayPrice;
                            prd.priceFormatted = this.formatMoney(dp);
                        }
                        this.products = products;
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving product data');
                        }
                    })

            },
            realTypeOf: function (obj) {
                //Returns the type of an object. i.e. String,Boolean,Date,Number, etc  http://mrrena.blogspot.com/2012/05/javascript-better-typeof-accurately.html
                return Object.prototype.toString.call(obj).slice(8, -1);
            },
            htmlrep: function (a) {	// To compress JSON a bit (This is set in shopxmlupdate.exe)
                //~( = <
                //~) = >
                return a.replace(/~\(/g, "<").replace(/~\)/g, ">");
            },
            formatMoney: function (value) {
                //format = "R|2|false|.|
                const p = this.inf;
                if (this.realTypeOf(value) == 'Number') {
                    let fmt = p.currencySymbol + '|' + (p.isTruncateCents ? 0 : p.currencyDecimalPlaces) + '|' + p.isCurrencySymbolSuffix + "|.";
                    
                    let amt = value.sjFormatMoney(fmt);
                    return amt;
                } else {
                    return value;
                }
            },
            formatMoneyInclCents: function (value) {
                //format = "R|2|false|.|
                const p = this.inf;
                return (this.realTypeOf(value) == 'Number') ? value.sjFormatMoney(p.currencySymbol + '|' + (p.isTruncateCents ? 0 : p.currencyDecimalPlaces) + '|' + p.isCurrencySymbolSuffix + "|.") : value;

            },
            addToBasket(id) {
                axios.post('/api/shop/addtobasket', { productId: id })
                    .then(({ data }) => {
                        this.totalBasketQty = data.totalBasketQty;
                        this.totalBasketPrice = data.totalBasketPrice;
                        this.msgBox(data.product + ' has been added to your basket.');

                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving basket data');
                        }
                    })

            },
            getBasket() {
                this.formSetState(this.fBasket, true, true) //formObj, resetState, resetForm
                axios.post('/api/shop/getbasket', { alloc: this.inf.alloc })
                    .then(({ data }) => {
                        this.totalBasketQty = data.totalBasketQty;
                        this.totalBasketPrice = data.totalBasketPrice;
                        this.basket = new sjData(data.basket).data;
                        this.pgMode = 'basket';

                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving basket data');
                        }
                    })
            },
            changeBasketQty(action, shopBasketId) {
                axios.post('/api/shop/changebasketqty', { action: action, shopBasketId: shopBasketId, alloc: this.inf.alloc })
                    .then(({ data }) => {
                        this.totalBasketQty = data.totalBasketQty;
                        this.totalBasketPrice = data.totalBasketPrice;
                        this.basket = new sjData(data.basket).data;
                    })
                    .catch((error) => {
                        if (error) {
                            this.msgBox('Error retrieving basket data');
                        }
                    })
            },
            checkout() {
                this.formSetState(this.fPlaceOrder, true);//formObj, resetState, resetForm
                this.fBasket.form.alloc = this.inf.alloc;
                axios.post('/api/shop/getCheckoutOrderSummary', this.fBasket.form)
                    .then(({ data }) => {
                        this.totalBasketQty = data.totalBasketQty;
                        this.totalBasketPrice = data.totalBasketPrice;
                        this.basket = new sjData(data.basket).data;
                        this.isVoucherOk = data.isVoucherOk;
                        this.grandTotal = data.grandTotal;
                        this.vfyTotal = data.vfyTotal;
                        this.fPlaceOrder.form.cellNum = data.cellNum;
                        this.voucherAmtDesc = data.voucherAmtDesc;
                        this.shopVoucherDiscountTotal = data.shopVoucherDiscountTotal;

                        this.pgMode = 'checkout';

                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fBasket.errors = error.response.data.formErrors;
                            this.formSetState(this.fBasket);
                        }
                    })
            },
            placeOrder() {
                this.fPlaceOrder.form.promoCode = this.fBasket.form.promoCode;
                this.fPlaceOrder.form.alloc = this.inf.alloc;
                this.fPlaceOrder.form.vfyTotal = this.vfyTotal;
                axios.post('/api/shop/placeOrder', this.fPlaceOrder.form)
                    .then(({ data }) => {

                        this.totalBasketQty = 0;
                        this.totalBasketPrice = 0;
                        this.basket = [];

                        this.msgBox('Your order has been placed successfully. Order No:' + data.orderNum);
                        this.pgMode = 'view';
						this.$router.push({ path: '/rewards/' + this.lastNavCat });

                        return;
                        /*
                        this.totalBasketQty = data.totalBasketQty;
                        this.totalBasketPrice = data.totalBasketPrice;
                        this.basket = new sjData(data.basket).data;
                        this.isVoucherOk = data.isVoucherOk;
                        this.grandTotal = data.grandTotal;
                        this.vfyTotal = data.vfyTotal;
                        this.fPlaceOrder.form.cellNum = data.cellNum;

                        this.pgMode = 'checkout';
                        */

                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.fBasket.errors = error.response.data.formErrors;
                            this.formSetState(this.fBasket);
                        }
                    })

            },

        }

    }
</script>
